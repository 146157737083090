import 'script-loader!.tmp/js/templates/components/Image/Image.js';
import 'script-loader!.tmp/js/templates/components/svg/icons.js';
import 'script-loader!.tmp/js/templates/location/modules/Articles.js';
import 'whatwg-fetch';
import { AccessibilityHelpers } from 'js/components/Util/Accessibility.js';

let accessibilityHelpersInstance = new AccessibilityHelpers();

export class ArticlesHearsay {
  constructor(cb) {
    this.template = location.ArticlesList;
    this.wrapper = document.querySelector('.js-articles-wrapper');
    this.title = this.wrapper.dataset.title ? this.wrapper.dataset.title : 'Articles & News';
    this.groupId = Yext.GroupId;
    this.endpoint = Yext.isStaging === 'true' ? 'https://600sgqum9e.execute-api.us-east-1.amazonaws.com/dev/client/citizens/getActivities'
    : 'https://1co7lyg2f5.execute-api.us-east-1.amazonaws.com/prod/client/citizens/getActivities';
    this.cb = cb;

    this.init();
  }

  async init() {
    if (this.groupId) {
      const data = await this.getData();
      this.wrapper.innerHTML = this.template({
        baseUrl: Yext.BaseUrl,
        data: data,
        title: this.title
      });
      this.attachHandlers();
      this.cb();
    }
  }

  async getData() {
    const response = await fetch(this.endpoint + `?groupId=${this.groupId}`);
    let data = await response.json();
    data = data.filter(article => location.is_valid_article(article) === 'true');
    return data ? data : [];
  }

  attachHandlers() {
    const expandEl = document.querySelector('.js-toggle-expand');
    const collapseEl = document.querySelector('.js-toggle-collapse');

    if (expandEl && collapseEl) {
      expandEl.addEventListener('click', this.toggleHandler);
      collapseEl.addEventListener('click', this.toggleHandler);
    }
  }

  toggleHandler() {
    const elements = document.querySelectorAll('.js-toggle-el');
    const expandEl = document.querySelector('.js-toggle-expand');
    const collapseEl = document.querySelector('.js-toggle-collapse');
    const list = document.getElementById('articles-list');

    for (const el of elements) {
      el.classList.toggle('is-hidden');
    }

    expandEl.classList.toggle('is-hidden');
    collapseEl.classList.toggle('is-hidden');
    accessibilityHelpersInstance.toggleAriaState(list, 'expanded');
  }
}