import 'script-loader!node_modules/slick-carousel/slick/slick.js';

export class ImageCarousel {
  constructor() {

    this.settings = {
      dots: true,
      arrows: false,
      accessibility: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 7000,
      slidesToShow: 1,
      slidesToScroll: 1,
      useTransform: false,
      rows: 0
    };
    
    this.el = $('.js-promoCarousel');
    this.el.on('init', this.addPlayPause.bind(this));
    this.el.slick(this.settings);
  }

  addPlayPause(event, slick) {
    this.playButton = document.createElement('button');
    this.buttonSpan =  document.createElement('span');
    this.buttonSpan.className = 'sr-only';
    this.buttonSpan.textContent = 'pause';
    this.playButton.appendChild(this.buttonSpan);
    this.playButton.addEventListener('click', this.playPause.bind(this));
    this.playButton.className = 'slick-playPause is-playing';
    slick.$dots[0].appendChild(this.playButton);
  }

  playPause() {
    if (this.playButton.classList.toggle('is-playing')) {
      this.el.slick('slickPlay');
      this.buttonSpan.textContent = 'pause';
    } else {
      this.el.slick('slickPause');
      this.buttonSpan.textContent = 'play';
    }
  }

  static getSlideEl(carouselEl, selector) {
    if (!carouselEl || !carouselEl.classList.contains('slick-initialized')) return;
    return carouselEl.querySelector('.slick-slide' + selector);
  }

  static goToSlide(carouselEl, selector, callback) {
    if (!carouselEl || !carouselEl.classList.contains('slick-initialized')) return;
    const slideEl = this.getSlideEl(carouselEl, selector);
    if (!slideEl) return;
    const index = slideEl.dataset.slickIndex;
    if (index == undefined) return;
    $(carouselEl).slick('slickGoTo', index, false);
    if (callback) {
      callback(slideEl, carouselEl, index);
    }
  }
}
