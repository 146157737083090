import 'script-loader!node_modules/jquery/dist/jquery.min.js';
import 'script-loader!node_modules/slick-carousel/slick/slick.js';

export default class NearbyBranches {
  static init() {
    for (let el of $('.NearbyBranches')) {
      el.NearbyBranches = new NearbyBranches(el);
    }
  }

  constructor (element) {
    this.element = element;

    const carousel = $('.NearbyBranches-carousel');
    if (carousel) {
      carousel.slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        rows: 0,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      });
    }
  }
}
