export default class Calculator {
  static init() {
    this.classSelector = '.Calculator';
    for (const el of $('.Calculator')) {
      el.Calculator = new Calculator(el);
    }
  }
  constructor (element) {
    this.element = element;

    this.calculator = document.getElementById('Calculator');
    this.leftToggle = this.calculator.querySelector('.js-leftToggle');
    this.leftFormWrapper = this.calculator.querySelector('.js-leftFormWrapper');
    this.rightToggle = this.calculator.querySelector('.js-rightToggle');
    this.rightFormWrapper = this.calculator.querySelector('.js-rightFormWrapper');
    this.rightWrapper = this.calculator.querySelector('.js-rightWrapper');
    this.rightWrapper2 = this.calculator.querySelector('.js-rightWrapper2');
    this.leftButton = this.calculator.querySelector('.js-leftButton');
    this.rightButton = this.calculator.querySelector('.js-rightButton');

    // the calculated values for the monthly payments tab
    this.amountText = document.getElementById('amountText');
    this.mortgageText = document.getElementById('mortgageText');

    // the calculated values for the maximum loan tab
    this.amountText2 = document.getElementById('amountText2');
    this.mortgageText2 = document.getElementById('mortgageText2');

    // error text message
    this.errorText = document.getElementById('errorText');
    this.errorText2 = document.getElementById('errorText2');

    this.toggleSetup();
    this.buttonSetup();
  }

  toggleSetup() {
    this.leftToggle.classList.toggle('is-clicked', true);
    this.rightFormWrapper.classList.toggle('is-hidden', true);

    this.leftToggle.addEventListener('click', () => {
      this.leftFormWrapper.classList.toggle('is-hidden', false);
      this.rightFormWrapper.classList.toggle('is-hidden', true);
      this.leftToggle.classList.toggle('is-clicked', true);
      this.rightToggle.classList.toggle('is-clicked', false);
      this.errorText.style.display = "none";
      this.errorText2.style.display = "none";
    });

    this.rightToggle.addEventListener('click', () => {
      this.rightFormWrapper.classList.toggle('is-hidden', false);
      this.leftFormWrapper.classList.toggle('is-hidden', true);
      this.rightToggle.classList.toggle('is-clicked', true);
      this.leftToggle.classList.toggle('is-clicked', false);
      this.errorText.style.display = "none";
      this.errorText2.style.display = "none";
    });
  }

  buttonSetup() {
    this.rightWrapper.classList.toggle('is-hidden', true);
    this.rightWrapper2.classList.toggle('is-hidden', true);

    this.leftButton.addEventListener('click', () => {
      // form inputs for the monthly payments tab
      this.loanAmount = document.getElementById('loanAmount').value;
      this.interestRate = document.getElementById('interestRate').value;
      this.loanTerm = document.getElementById('loanTerm').value;

      if(this.validateForm(this.interestRate, this.loanTerm)) {
        this.rightWrapper.classList.toggle('is-hidden', false);
        this.amountText.innerHTML = this.calculateMonthlyPayments(this.loanAmount, this.interestRate, this.loanTerm);
        this.mortgageText.innerHTML = this.calculateTotalMortgage(this.loanAmount, this.interestRate, this.loanTerm);
        this.errorText.style.display = "none";
      }

      else {
        this.errorText.style.display = "block";
      }
    });

    this.rightButton.addEventListener('click', () => {
      // form inputs for the monthly payments tab
      this.monthlyPayment = document.getElementById('monthlyPayment').value;
      this.interestRate2 = document.getElementById('interestRate2').value;
      this.loanTerm2 = document.getElementById('loanTerm2').value;

      if(this.validateForm(this.interestRate2, this.loanTerm2)) {
        this.rightWrapper2.classList.toggle('is-hidden', false);
        this.amountText2.innerHTML = this.calculateMaximumLoan(this.monthlyPayment, this.interestRate2, this.loanTerm2);
        this.mortgageText2.innerHTML = this.calculateTotalMortgage2(this.monthlyPayment, this.loanTerm2);
        this.errorText2.style.display = "none";
      }

      else {
        this.errorText2.style.display = "block";
      }
    })
  }

  validateForm(interestRate, loanTerm) {
    this.interestRate = interestRate;
    this.loanTerm = loanTerm.replace(/\D/g,'');

    if(this.interestRate == "" || this.loanTerm == "" || this.loanTerm < 120 || this.loanTerm > 360) {
      return false;
    }

    return true;
  }

  calculateMonthlyPayments(loanAmount, interestRate, loanTerm) {
    // remove any non-numeric characters
    this.loanAmount = loanAmount.replace(/\D/g,'');
    this.interestRate = interestRate;
    this.loanTerm = loanTerm.replace(/\D/g,'');

    // change percent to decimal and divide by 12 months
    let newInterest = this.interestRate / 1200;
    let exp = Math.pow(1+newInterest, loanTerm);
    let monthlyPayment = (this.loanAmount*[newInterest*exp])/(exp - 1);

    // round to nearest cent and format with commas
    return '$' + monthlyPayment.toLocaleString(undefined, {maximumFractionDigits: 0});
  }

  calculateMaximumLoan(monthlyPayment, interestRate, loanTerm) {
    // remove any non-numeric characters
    this.monthlyPayment = monthlyPayment.replace(/\D/g,'');
    this.interestRate = interestRate;
    this.loanTerm = loanTerm.replace(/\D/g,'');

    let newInterest = this.interestRate / 1200;
    let exp = Math.pow(1+newInterest, loanTerm);
    let loanAmount = (this.monthlyPayment*(exp - 1))/[newInterest*exp];

    // round to the nearest cent and format with commas
    return '$' + loanAmount.toLocaleString(undefined, {maximumFractionDigits: 0});
  }

  calculateTotalMortgage(loanAmount, interestRate, loanTerm) {
    // remove any non-numeric characters
    this.loanAmount = loanAmount.replace(/\D/g,'');
    this.interestRate = interestRate;
    this.loanTerm = loanTerm.replace(/\D/g,'');

    // change percent to decimal and divide by 12 months
    let newInterest = this.interestRate / 1200;
    let exp = Math.pow(1+newInterest, loanTerm);
    let monthlyPayment = (this.loanAmount*[newInterest*exp])/(exp - 1);

    // round to nearest cent and format with commas
    return 'Total Cost of Mortgage $' + (monthlyPayment*this.loanTerm).toLocaleString(undefined, {maximumFractionDigits: 0});
  }

  calculateTotalMortgage2(monthlyPayment, loanTerm) {
    this.monthlyPayment = monthlyPayment.replace(/\D/g,'');
    this.loanTerm = loanTerm.replace(/\D/g,'');

    // round to the nearest pnny and format with commas
    return 'Total Cost of Mortgage $' + (this.monthlyPayment * this.loanTerm).toLocaleString(undefined, {maximumFractionDigits: 0});
  }
}
