/**
 * Citizensbank is passing some rich text as ^test^ where test is supposed
 * to be superscripted. This class replaces ^test^ with <sup>test</sup>
 * within a specified scope.
 */

 export class Superscripts {
  static run(scope) {
    const el = document.querySelector(scope);

    if (el) {
      let elHtml = el.innerHTML;
      const matches = elHtml.matchAll(/\^(.*?[^\\])\^/g);
      for (const match of matches) {
        elHtml = elHtml.replace(match[0], `<sup>${match[1]}</sup>`);
      }
      el.innerHTML = elHtml;
    }
  }
}
